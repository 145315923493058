export type TimelineProps = {
  children: React.ReactNode
}

export const Timeline = ({ children }: TimelineProps) => (
  <ol className="relative border-l-4 border-orange-500">{children}</ol>
)

Timeline.displayName = 'Timeline'

export default Timeline
