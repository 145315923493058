import ProgressBar from '@ramonak/react-progress-bar'
import { useEffect } from 'react'
import Modal from './modal'
import * as UI from '@/ui'

export type ProgressModalProps = {
  title: string | React.ReactNode
  description?: string | React.ReactNode
  children?: React.ReactNode
  autoClose?: boolean
  stages?: number
  currentStage?: number
  show: boolean
  onClose: () => void
}

export const ProgressModal = ({
  title,
  description,
  children,
  currentStage = 0,
  stages = 0,
  autoClose,
  show,
  onClose,
}: ProgressModalProps) => {
  const closeModal = () => {
    if (currentStage === stages) {
      onClose && onClose()
    } else {
      return null
    }
  }

  useEffect(() => {
    if (autoClose && currentStage === stages) {
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStage])

  return (
    <Modal show={show} onClose={closeModal}>
      <UI.Block>
        <UI.Heading size="xs">{title}</UI.Heading>

        {description && <UI.Paragraph>{description}</UI.Paragraph>}

        <ProgressBar completed={(100 / (stages || 1)) * currentStage} />

        <div className="max-h-[50vh] min-w-[300px] overflow-y-scroll">{children}</div>

        <UI.GridSpan align="center">
          {currentStage === stages && (
            <UI.Button theme="basic" color="secondary" onClick={closeModal}>
              Close Window
            </UI.Button>
          )}
        </UI.GridSpan>
      </UI.Block>
    </Modal>
  )
}

export default ProgressModal
