import { DateStamp, toRegionalDate } from '../../../helpers/date'
import * as UI from '@/ui'

export type ItemProps = {
  children?: React.ReactNode | string
  date: DateStamp
  email?: string
  title: string
  color?: 'blue' | 'orange'
}

// Tailwind: border-orange-500 border-blue-500

export const Item = ({ children, email, date, title, color = 'orange' }: ItemProps) => (
  <li className="mb-8 ml-4">
    <div
      className={`absolute -left-2.5 mt-1.5 h-[15px] w-[15px] rounded-full border-4 bg-white border-${color}-500`}
    ></div>
    <time className="mb-1 text-sm leading-none">
      <UI.Grid size="flow">
        <span className="font-normal text-gray-400">{toRegionalDate(date)}</span>
        {email && (
          <span className="font-normal text-blue-500">
            <UI.Link to={['email', email]} target="_blank">
              {email}
            </UI.Link>
          </span>
        )}
      </UI.Grid>
    </time>
    <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
    {children && <p className="mb-4 text-base font-normal text-gray-500">{children}</p>}
  </li>
)

Item.displayName = 'Timeline.Item'

export default Item
