import { Disclosure, DisclosurePanel, DisclosureButton } from '@headlessui/react'
import { UI } from '..'
import { useUi } from './use-ui'
import { Breakpoints, useBreakpoint } from './use-breakpoint'
import DownArrowIcon from '@/images/icons/arrowDown_icon.svg?svgr'
import { cn } from '@/helpers/tailwind'

export type ButtonSpacing = 'tight' | 'between'

export type AccordionProps = {
  items: {
    title: string | React.ReactNode
    content: string | React.ReactNode
  }[]
  gap?: 'xs' | 'small' | 'medium' | 'large' | 'xl'
  textSize?: 'xs' | 'small' | 'medium' | 'large' | 'xl'
  contentTag?: 'span' | 'h2'
  chevronStyles?: string
  divided?: boolean
  leftBorder?: boolean
  buttonSpacing?: Breakpoints<ButtonSpacing>
  open?: number[]
}

export const Accordion = ({
  items,
  gap = 'medium',
  textSize = 'medium',
  chevronStyles = 'text-selphWhite-500',
  contentTag = 'span',
  divided = true,
  buttonSpacing = 'between',
  leftBorder = false,
  open = [],
}: AccordionProps) => {
  const currentButtonSpacing = useBreakpoint<ButtonSpacing>(buttonSpacing)

  const gaps = {
    xs: 1, // mb-1 space-y-1 pt-1 ml-1
    small: 2, // mb-2 space-y-2 pt-2 ml-2
    medium: 4, // mb-5 space-y-5 pt-5 ml-5
    large: 6, // mb-6 space-y-6 pt-6 ml-6
    xl: 10, // mb-10 space-y-10 pt-10 ml-10
  }

  const textTitleSizes = {
    xs: 'text-sm',
    small: 'text-base',
    medium: 'text-lg',
    large: 'text-xl',
    xl: 'text-xxl',
  }

  const textContentSizes = {
    xs: 'text-xs',
    small: 'text-sm',
    medium: '',
    large: 'text-lg',
    xl: 'text-xl',
  }

  const ui = useUi({
    styles: {
      textContentSizes: { options: textContentSizes, selected: textSize },
    },
    name: 'Accordion',
    className: `${divided && 'border-y-selphBlue-600 border-b-2'}`,
  })

  const uiTitle = useUi({
    styles: {
      textTitleSizes: { options: textTitleSizes, selected: textSize },
    },
    name: 'Accordion',
  })

  return (
    <div className={ui.className}>
      <dl>
        <ul className={`mb-${gaps[gap]} space-y-${gaps[gap]} list-none`}>
          {items.map((item, i) => (
            <li key={i}>
              <Disclosure
                as="div"
                className={`py-${gaps[gap]} rounded-lg bg-selphWhite-100 px-3 md:px-6`}
                defaultOpen={open.includes(i + 1)}
              >
                {({ open }) => (
                  <div className={leftBorder ? `} border-l-2 pl-2` : ''}>
                    <dt className={uiTitle.className}>
                      <DisclosureButton
                        className={`flex w-full text-left ${
                          currentButtonSpacing != 'tight' && 'justify-between'
                        } items-center gap-x-3`}
                      >
                        {contentTag === 'span' && <span className="font-medium text-selphBlack">{item.title}</span>}
                        {contentTag === 'h2' && <h2 className="font-normal text-selphBlack">{item.title}</h2>}

                        <span className="flex items-center justify-center">
                          <DownArrowIcon
                            className={cn(
                              open ? '-rotate-180' : 'rotate-0',
                              `${chevronStyles} size-6 transform rounded-full bg-selphAmber-500 p-1 duration-300`,
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>

                    <dd className="hidden">{item.content}</dd>

                    <DisclosurePanel as="dd" className="mt-2">
                      {typeof item.content === 'string' ? <UI.Paragraph>{item.content}</UI.Paragraph> : item.content}
                    </DisclosurePanel>
                  </div>
                )}
              </Disclosure>
            </li>
          ))}
        </ul>
      </dl>
    </div>
  )
}

Accordion.displayName = 'Accordion'

export default Accordion
