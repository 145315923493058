export type TabMenuProps = {
  children: React.ReactNode
}

export const TabMenu = ({ children }: TabMenuProps) => (
  <nav className="-mb-px flex space-x-8 border-b border-gray-200 sm:block" aria-label="Tabs">
    {children}
  </nav>
)

TabMenu.displayName = 'TabMenu.Menu'

export default TabMenu
