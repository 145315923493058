export interface ShadowedContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode
}

export const ShadowedContainer: React.FC<ShadowedContainerProps> = ({ children, className, ...props }) => {
  return (
    <div className={`mx-auto w-fit overflow-clip rounded-xl bg-selphWhite-100 drop-shadow-lg ${className}`} {...props}>
      {children}
    </div>
  )
}

ShadowedContainer.displayName = 'ShadowedContainer'

export default ShadowedContainer
