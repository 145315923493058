import { stateBGColors } from '../chart-colors'
import { useUi } from '../use-ui'
import { TableBackgroundColors, useTable } from './table'

export type FootProps = {
  className?: string
  children: React.ReactNode
} & Omit<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>,
  'className' | 'children'
>

export const Foot = ({ children, className, ...props }: FootProps) => {
  const { backgroundColor } = useTable()

  const backgroundColors: { [key in TableBackgroundColors]: string } = {
    transparent: '',
    alternate: '',
    ...stateBGColors,
  }

  const ui = useUi({
    styles: {
      backgroundColor: { options: backgroundColors, selected: backgroundColor },
    },
    name: 'Table.Foot',
    className: className,
  })

  return (
    <tfoot className={ui.className} {...props}>
      {children}
    </tfoot>
  )
}

Foot.displayName = 'Table.Foot'

export default Foot
