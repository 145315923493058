import { Tab, TabList, TabPanel, TabPanels, TabGroup } from '@headlessui/react'
import { isEmpty } from 'lodash-es'
import TrustpilotBox from '../Trustpilot/trustpilot-box'
import { cn } from '../../helpers/tailwind'
import { ImageProps } from './index'
import PlayVideoIcon from '@/images/playVideoIcon.svg?svgr'
import * as UI from '@/ui'

export type GalleryProps = {
  images: GalleryImages
  videos?: GalleryVideos | null
  imageProps?: ImageProps[]
  className?: string
}
export type GalleryImage = {
  name: string
  src: string
  alt: string
}

export type GalleryVideos = {
  url: string
  placeholderSrc: string
  alt: string
}[]

export type GalleryImages = GalleryImage[]

export const Gallery = ({ videos, images, imageProps, className }: GalleryProps) => {
  const list = (
    <TabList>
      <div className="flex flex-row flex-wrap gap-x-2 gap-y-3">
        {images.map((image, key) => (
          <Tab
            key={key}
            className="relative flex h-18 w-26 cursor-pointer items-center justify-center rounded-full text-sm font-medium text-gray-900 uppercase focus:outline-hidden md:h-20 md:w-28 lg:h-24 lg:w-32"
          >
            {({ selected }) => (
              <>
                <span className="sr-only">{image.name}</span>
                <span className="absolute inset-0 overflow-hidden rounded-md">
                  <UI.Image
                    {...(imageProps ? imageProps[key] : { src: image.src })}
                    width={136}
                    height={118}
                    priority
                    alt="Product thumbnail"
                    className={cn(
                      selected ? 'opacity-100' : 'opacity-50',
                      `h-full w-full rounded-xl object-cover object-center`,
                    )}
                  />
                </span>
              </>
            )}
          </Tab>
        ))}

        {videos &&
          !isEmpty(videos) &&
          videos.map((vid, key) => (
            <Tab
              key={key}
              className="relative flex h-24 w-32 cursor-pointer items-center justify-center rounded-full text-sm font-medium text-gray-900 uppercase focus:outline-hidden"
            >
              {({ selected }) => (
                <>
                  <span className="sr-only">{vid.alt}</span>
                  <span className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-md">
                    <PlayVideoIcon className="absolute h-12 w-12 text-selphOrange-500" />
                    <UI.Image
                      src={vid.placeholderSrc}
                      width={96}
                      height={45}
                      priority
                      alt={vid.alt}
                      className={cn(
                        selected ? 'opacity-100' : 'opacity-50',
                        `h-full w-full rounded-xl bg-selphGrey-100 object-scale-down object-center`,
                      )}
                    />
                  </span>
                </>
              )}
            </Tab>
          ))}
      </div>
    </TabList>
  )

  return (
    <TabGroup as="div" className={className} id="product-gallery">
      {/* Image selector */}
      <div className="mx-auto flex flex-col flex-wrap-reverse items-end justify-center gap-x-5 gap-y-5 max-md:max-w-md">
        <TabPanels className="w-full md:max-w-[576px]">
          {images.map((image, key) => (
            <TabPanel key={key} className="relative">
              <UI.Image
                {...(imageProps ? imageProps[key] : { src: image.src, width: 400, height: 400 })}
                alt={image.alt}
                priority
                placeholder="blur"
                className="w-full rounded-2xl object-cover object-center"
              />
            </TabPanel>
          ))}

          {videos &&
            !isEmpty(videos) &&
            videos.map((vid, key) => (
              <TabPanel key={key} className="relative flex aspect-square h-full flex-col justify-center">
                <iframe
                  src={vid.url}
                  title={vid.alt}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className="aspect-video w-full rounded-2xl"
                />
              </TabPanel>
            ))}
        </TabPanels>
        <div className="max-sm:hidden">
          <TrustpilotBox boxStyle="withoutLabel" />
        </div>
        {images.length > 1 && list}
      </div>
    </TabGroup>
  )
}

Gallery.displayName = 'Gallery'

export default Gallery
