import { DateStamp, toRegionalDate } from '../../helpers/date'
import * as UI from '@/ui'

export type AuditProps = {
  date?: DateStamp
  email?: string
  changes: {
    field: string
    before: string
    after: string
  }[]
}

export const Audit = ({ date, email, changes }: AuditProps) => (
  <UI.Panel.Panel>
    {(date || email) && (
      <UI.Panel.Head>
        <UI.Grid size="flow">
          {date && toRegionalDate(date)}
          {email && (
            <span className="font-normal text-blue-500">
              <UI.Link to={['email', email]} target="_blank">
                {email}
              </UI.Link>
            </span>
          )}
        </UI.Grid>
      </UI.Panel.Head>
    )}

    <UI.Table.Table size="small" className="text-xs">
      <UI.Table.Head>
        <UI.Table.H>Field</UI.Table.H>
        <UI.Table.H>Before</UI.Table.H>
        <UI.Table.H>After</UI.Table.H>
      </UI.Table.Head>
      <UI.Table.Body>
        {changes.map((change, key) => (
          <UI.Table.Row key={key}>
            <UI.Table.H>{change.field}</UI.Table.H>
            <UI.Table.D className="bg-red-100">{change.before}</UI.Table.D>
            <UI.Table.D className="bg-green-100">{change.after}</UI.Table.D>
          </UI.Table.Row>
        ))}
      </UI.Table.Body>
    </UI.Table.Table>
  </UI.Panel.Panel>
)

Audit.displayName = 'Audit'

export default Audit
