import { useState } from 'react'
import { DateStamp, toRegionalDate } from '../../../helpers/date'
import * as UI from '@/ui'

export type GroupProps = {
  date: DateStamp
  title: React.ReactNode | string
  summary: React.ReactNode | string
  expand?: boolean
  children: React.ReactNode
}

export const Group = ({ date, title, summary, expand = false, children }: GroupProps) => {
  const [visible, setVisible] = useState<boolean>(expand)

  return (
    <li className="mb-8 ml-4">
      <div className="absolute -left-3.5 mt-1.5 h-[25px] w-[25px] rounded-full border-4 border-orange-500 bg-white text-center">
        <UI.Button
          type="text"
          className={`-mt-[6px] px-[${visible ? '5' : '3'}px] text-xl font-bold text-orange-500`} // Tailwind: px-[3px] px-[5px]
          onClick={() => setVisible(!visible)}
        >
          {visible ? '-' : '+'}
        </UI.Button>
      </div>
      <time className="mb-1 text-sm leading-none">
        <UI.Grid size="flow">
          <span className="font-normal text-gray-400">{toRegionalDate(date)}</span>
        </UI.Grid>
      </time>
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      {summary && <p className="mb-4 text-base font-normal text-gray-500">{summary}</p>}
      {visible && (
        <div className="ml-2">
          <ol className="relative border-l-4 border-blue-500">{children}</ol>
        </div>
      )}
    </li>
  )
}

Group.displayName = 'Timeline.Group'

export default Group
