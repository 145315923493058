import * as React from 'react'
import { useRouter } from 'next/router'
import { getHref, RoutesLink } from '../../../routes'
import * as UI from '@/ui'

export type ItemProps = {
  children: React.ReactNode | string
  to: RoutesLink
  icon?: React.FC<React.ComponentProps<'svg'>>
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const Item = ({ children, to, icon }: ItemProps) => {
  const { asPath } = useRouter()

  const Icon = icon

  const current = asPath === getHref(to as RoutesLink)

  return (
    <UI.Link
      to={to as RoutesLink}
      className={classNames(
        current
          ? 'border-selphBlue-300 text-selphBlue-400'
          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
        'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium',
      )}
      aria-current={current ? 'page' : undefined}
      color="none"
    >
      {Icon && (
        <Icon
          className={classNames(
            current ? 'text-selphBlue-300' : 'text-gray-400 group-hover:text-gray-500',
            'mr-2 -ml-0.5 h-5 w-5',
          )}
          aria-hidden="true"
        />
      )}

      <span>{children}</span>
    </UI.Link>
  )
}

Item.displayName = 'TabMenu.Item'

export default Item
